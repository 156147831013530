import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Phaser from 'phaser';
import { CryptoRumbleHeroesStart } from './main';
import { EventBus } from './EventBus';
import styled from 'styled-components';

export interface IRefPhaserGame {
  game: Phaser.Game | null;
  scene: Phaser.Scene | null;
}

interface IProps {
  currentActiveScene?: (sceneInstance: Phaser.Scene) => void;
  className?: string;
}

export const PageGamePhaserGame = forwardRef<IRefPhaserGame, IProps>(({ currentActiveScene, className }, ref) => {
  const game = useRef<Phaser.Game | null>(null);

  useLayoutEffect(() => {
    if (game.current) return;
    game.current = CryptoRumbleHeroesStart({ parent: 'heroes-container', width: 2000, height: 3800 });
    if (typeof ref === 'function') {
      ref({ game: game.current, scene: null });
    } else if (ref) {
      ref.current = { game: game.current, scene: null };
    }
    return () => {
      if (!game.current) return;
      game.current.destroy(true);
      game.current = null;
    };
  }, [ref]);

  useEffect(() => {
    EventBus.on('current-scene-ready', (sceneInstance: Phaser.Scene) => {
      currentActiveScene?.(sceneInstance);
      if (typeof ref === 'function') {
        ref({ game: game.current, scene: sceneInstance });
      } else if (ref) {
        ref.current = { game: game.current, scene: sceneInstance };
      }
    });
    return () => {
      EventBus.removeListener('current-scene-ready');
    };
  }, [currentActiveScene, ref]);

  return <CptStyle className={className} id="heroes-container"></CptStyle>;
});

const CptStyle = styled.div`
  width: 100vw;
  background-color: #fff;
  > canvas {
    display: block;
    width: 100%;
  }
`;
