import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PageGamePhaserGame } from './CryptoRumbleHeroes/PhaserGame';

export const PageGame: React.FC<{}> = (props) => {
  return (
    <PageIndexStyle>
      <PageGamePhaserGame className="heroes" />
    </PageIndexStyle>
  );
};

const PageIndexStyle = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
`;
