import { formatUnits } from 'viem';

export const sleep = (m: number) => new Promise((r) => setTimeout(r, m));

export const fmtNumber = (value?: string | bigint | null, decimals?: number, fixed?: number) => {
  const str = formatUnits(value ? BigInt(value) : 0n, decimals ?? 18);
  const nums = str.split('.');
  nums[0] = nums[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (!nums[1] || nums[1].match(/^0+$/)) return nums[0];
  if (typeof fixed === 'number') {
    nums[1] = nums[1].slice(0, fixed);
  }
  return nums.join('.');
};

export const fmtInt = (num?: number | bigint | string) => {
  if (!num) return '0';
  const str = String(num);
  const nums = str.split('.');
  nums[0] = nums[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (!nums[1] || nums[1].match(/^0+$/)) return nums[0];
  return nums.join('.');
};

export const errorParse = (e: any) => {
  console.error('errorParse', e);
  if (!e) return 'unknown error';
  let msg = String(e);

  if (typeof e === 'object') {
    if (typeof e.data === 'object' && e.data.message) {
      msg = String(e.data.message);
    } else if (typeof e.message === 'string') {
      msg = String(e.message);
    } else if (typeof e.reason === 'string') {
      msg = e.reason;
    }
  }
  msg = msg.replace(/TransactionExecutionError: /, '');
  msg = msg.replace(/\n\nRequest Arguments:([\w\W]*)/, '');
  msg = msg.replace(/\n\nRaw Call Arguments:([\w\W]*)/, '');

  msg = msg.replace(/Contract Call:([\w\W]*)/, '');

  // TransactionExecutionError: User rejected the request. Request Arguments: from: 0x34df25eae393ab
  msg = msg.replace(/(.*){\\"code\\":-32000,\\"message\\":\\"(.*?)\\"}}([\w\W]*)/, '$2');
  if (msg.match(/underlying network changed/)) msg = 'underlying network changed';
  msg = msg.replace(/(.*)execution reverted: (.*?)"(.*)/, '$2').replace(/\\$/, '');
  msg = msg.replace(/VM Exception while processing transaction: /, '');
  msg = msg.replace(/\(action="sendTransaction", transaction=(.*)/, '');
  msg = msg.replace(/Error: /, '');
  if (msg.match(/SilenceError:/)) return '';
  msg = msg.slice(0, 500);
  msg = msg.replace(/\[ See: https:\/\/links.ethers.org\/(.*)/, '');
  gtag('event', 'exception', { description: msg.slice(0, 50), fatal: false });

  return msg;
};

export const is0xString = (tx: any): tx is `0x${string}` => {
  if (!tx) return false;
  if (typeof tx !== 'string') return false;
  if (!tx.match(/^0x/)) return false;
  return true;
};

export const txEllipsis = (text = '', start: number = 6, end: number = 4) => {
  if (text.length <= start + end) return text;
  return `${text.substring(0, start)}...${text.substring(text.length - end)}`;
};
