import { useEffect } from 'react';

export const AppInitVh = () => {
  useEffect(() => {
    const initVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh.toFixed(4)}px`);
    };
    initVH();
    window.addEventListener('resize', initVH);
  }, []);
  return null;
};
