import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as TwitterSvg } from 'assets/images/footer/twitter.svg';
import { ReactComponent as TelegramSvg } from 'assets/images/footer/telegram.svg';
import { ReactComponent as DiscordSvg } from 'assets/images/footer/discord.svg';

const year = new Date().getFullYear();

export const AppFooter: React.FC<{}> = (props) => {
  return (
    <CptStyles>
      <div className="items">
        <TwitterSvg />
        <TelegramSvg />
        <DiscordSvg />
      </div>
      <div className="text">© {year} Crypto Rumble. All rights reserved.</div>
    </CptStyles>
  );
};

const CptStyles = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  > .items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    color: #fff;
  }
  > .text {
    color: rgba(88, 88, 88, 1);
    font-size: 12px;
    font-weight: 500;
  }
`;
