import Phaser, { GameObjects, Scene } from 'phaser';

import { EventBus } from '../EventBus';
import { PageIndexHeroes } from '../main';
import { SpineGameObject } from '@esotericsoftware/spine-phaser';
import { sleep } from 'utils';

export class MainMenu extends Scene {
  constructor() {
    super('MainMenu');
  }

  async create() {
    while (!this.add.spine) {
      console.log('not spine');
      await sleep(1000);
    }
    const atkMap: Record<string, { hero: SpineGameObject; conf: (typeof PageIndexHeroes)[number] }> = {};
    PageIndexHeroes.forEach((conf) => {
      const hero = this.add.spine(conf.left, conf.top, `${conf.name}-json`, `${conf.name}-atlas`);
      atkMap[conf.name] = { hero, conf };
      hero.scaleY = conf.scaleY;
      hero.scaleX = conf.scaleX;
      hero.setInteractive();
      // console.log(hero.animationStateData.skeletonData);
      hero.animationState.setAnimation(0, 'idle', true);

      const animationNames = hero.animationStateData.skeletonData.animations.map((a) => a.name);
      console.log(conf.name, animationNames);
      const attackAction = 'attack'; // animationNames.includes('attack2') ? 'attack2' : 'attack';
      const resetHero = () => {
        this.tweens.add({ targets: hero, scaleX: conf.scaleX, scaleY: conf.scaleY, duration: 200 });
      };
      hero.animationState.addListener({
        event: (entry, event) => {
          console.log(conf.name, 'event', event);
          if (conf.atkItems && event.data.name === 'attackEffact') {
            conf.atks.forEach((target) => {
              const to = atkMap[target]?.hero;
              if (!to) return;
              const xx = hero.scaleX / Math.abs(hero.scaleX);
              const txx = to.scaleX / Math.abs(to.scaleX);
              const fromXY = { x: hero.x + conf.atkItems.bx * xx, y: hero.y + conf.atkItems.by };
              const toXY = { x: to.x + conf.atkItems.tx * txx, y: to.y + conf.atkItems.ty };
              const rotation = Phaser.Math.Angle.Between(fromXY.x, fromXY.y, toXY.x, toXY.y);
              // const vec = new Phaser.Math.Vector2(tx - x, ty - y)
              // const rotation = vec.angle()
              const lines: Array<SpineGameObject | GameObjects.Image> =
                conf.atkItems.items?.map((name) => {
                  const item = this.add.image(fromXY.x, fromXY.y, name).setRotation((conf.atkItems.dr || Math.PI / 2) + rotation);
                  item.scale = conf.atkItems.scale || 1;
                  item.scaleX = xx * item.scale;
                  return item;
                }) || [];
              // lines[0].setAlpha(0);
              this.tweens.add({
                targets: lines,
                x: toXY.x,
                y: toXY.y,
                duration: 200,
                alpha: { from: 0.8, to: 1 },
                delay: (tar: any, tKey: any, value: any, tIndex: number, total: any, tween: any) => {
                  return tIndex * 10;
                },
                onComplete: () => {
                  to.animationState.setAnimation(0, 'hit', false);
                  to.animationState.addAnimation(0, 'idle', true, 0);
                  lines.forEach((l) => l.destroy());
                },
              });
            });
          }
          if (!conf.atkItems && event.data.name === 'attackHit') {
            conf.atks.forEach((target) => {
              atkMap[target]?.hero.animationState.setAnimation(0, 'hit', false);
              atkMap[target]?.hero.animationState.addAnimation(0, 'idle', true, 0);
            });
          }
        },
        complete(entry) {
          if (entry.animation?.name !== attackAction) return;
          console.log(conf.name, 'complete', entry);
        },
      });
      hero.on('clicked', () => {
        if (conf.atkxy) {
          const tHero = atkMap[conf.atks[0]]?.hero;
          if (!tHero) return;
          const origin = { x: conf.left, y: conf.top, z: hero.z };
          const onAttackEnd = () => {
            hero.setDepth(origin.z);
            hero.animationState.addAnimation(0, 'idle', true, 0);
          };
          const onAttackBegin = () => {
            hero.animationState.setAnimation(0, attackAction, false).listener = {
              complete: () => {
                this.tweens.add({ targets: [hero], x: origin.x, y: origin.y, duration: 100, ease: 'Sine.easeInOut', onComplete: onAttackEnd });
              },
            };
          };
          const txx = tHero.scaleX / Math.abs(tHero.scaleX);
          const targetX = tHero.x + conf.atkxy.dx * txx;
          const targetY = tHero.y - conf.atkxy.dy;
          hero.setDepth(1);
          this.tweens.add({ targets: [hero], x: targetX, y: targetY, duration: 100, ease: 'Sine.easeInOut', onComplete: onAttackBegin });
        } else {
          hero.animationState.setAnimation(0, attackAction, false);
          hero.animationState.addAnimation(0, 'idle', true, 0);
        }
      });
      hero.on('GAMEOBJECT_OVER', () => {
        // this.tweens.add({ targets: hero, scaleX: conf.scaleX * 1.1, scaleY: conf.scaleY * 1.1, duration: 200 });
      });
      hero.on('GAMEOBJECT_OUT', () => {
        // resetHero();
      });
    });
    this.input.on(Phaser.Input.Events.GAMEOBJECT_DOWN, (aaa: any, bbb: any) => {
      bbb.emit('clicked', bbb);
    });
    this.input.on(Phaser.Input.Events.GAMEOBJECT_OVER, (aaa: any, bbb: any) => {
      bbb.emit('GAMEOBJECT_OVER', bbb);
    });
    this.input.on(Phaser.Input.Events.GAMEOBJECT_OUT, (aaa: any, bbb: any) => {
      bbb.emit('GAMEOBJECT_OUT', bbb);
    });

    setInterval(() => {
      const temp = PageIndexHeroes[Math.floor(Math.random() * PageIndexHeroes.length)];
      const item = atkMap[temp.name];
      if (!item) return;
      item.hero.emit('clicked');
    }, 3000);

    EventBus.emit('current-scene-ready', this);
    EventBus.on('change-action', async (action: string) => {
      // FallenProphet.animationState.setAnimation(0, action, true);
    });
  }
}
