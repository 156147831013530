import Phaser, { GameObjects, Scene } from 'phaser';

import { EventBus } from '../EventBus';
import { PageIndexHeroes } from '../main';
import { SpineGameObject } from '@esotericsoftware/spine-phaser';

const anis = ['attack', 'hit', 'attack2', 'effact'];

export class MainMenu extends Scene {
  constructor() {
    super('MainMenu');
  }

  create() {
    let top = 300;
    PageIndexHeroes.forEach((name) => {
      let left = 200;
      const hero = this.add.spine(left, top, `${name}-json`, `${name}-atlas`);
      hero.scaleX = 1;
      hero.scaleY = 1;
      console.log(
        name,
        hero.animationStateData.skeletonData.animations.map((a) => a.name),
      );
      // hero.setInteractive();
      hero.animationState.setAnimation(0, 'idle', true);
      hero.animationStateData.skeletonData.animations.forEach((ani) => {
        const index = anis.indexOf(ani.name);
        if (index === -1) return;
        const item = this.add.spine(left + 400 * (index + 1), top, `${name}-json`, `${name}-atlas`);
        item.scaleX = 1;
        item.scaleY = 1;
        item.animationState.setAnimation(0, ani.name, true);
      });
      top += 400;
    });
    EventBus.emit('current-scene-ready', this);
    EventBus.on('change-action', async (action: string) => {
      // FallenProphet.animationState.setAnimation(0, action, true);
    });
  }
}
