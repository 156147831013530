import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CryptoRumbleIndex } from './CryptoRumbleIndex';

export const PageIndexTest: React.FC<{}> = (props) => {
  return (
    <LayoutDefault>
      <PageIndexStyle>
        <CryptoRumbleIndex />
      </PageIndexStyle>
    </LayoutDefault>
  );
};

const PageIndexStyle = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: scale(1);
  > .DownSvg {
    position: absolute;
    top: calc(var(--vh, 1vh) * 94);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    animation: CrTitleAni2 ease-in-out 1s infinite;
    opacity: 0.8;
    @keyframes CrTitleAni2 {
      0% {
        transform: scale(1) translateY(0);
      }
      50% {
        transform: scale(1.2) translateY(10px);
      }
      100% {
        transform: scale(1) translateY(0);
      }
    }
  }
  @media screen and (min-height: 63vw) {
    > .DownSvg {
      display: none;
    }
  }
`;
