import Phaser, { AUTO, Game } from 'phaser';
import { SpinePlugin } from '@esotericsoftware/spine-phaser';
import { Boot } from './scenes/Boot';
import { MainMenu } from './scenes/MainMenu';

//  Find out more information about the Game Config at:
//  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config: Phaser.Types.Core.GameConfig = {
  type: AUTO,
  backgroundColor: 'transparent',
  transparent: true,
  scene: [Boot, MainMenu],
  input: { mouse: { preventDefaultWheel: false } },
  plugins: {
    scene: [{ key: 'spine.SpinePlugin', plugin: SpinePlugin, mapping: 'spine' }],
  },
};

export const PageIndexHeroes = ['Thief', 'RadiantMage', 'Magician', 'FallenProphet', 'ScarletMage', 'DwarfGunslinger', 'GodOfWar', 'HeadlessRider', 'StoneWarrior'];

export const CryptoRumbleHeroesStart = (option: Partial<Phaser.Types.Core.GameConfig>) => {
  console.log('CryptoRumbleHeroesStart');
  return new Game({ ...config, ...option });
};
