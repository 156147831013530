import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Phaser from 'phaser';
import { CryptoRumbleHeroesStart } from './main';
import { EventBus } from './EventBus';
import styled from 'styled-components';

export interface IRefPhaserGame {
  game: Phaser.Game | null;
  scene: Phaser.Scene | null;
}

const realWidth = 1440;
const realHeight = 800;
const minWidth = 0; // (600 * realWidth) / realHeight;
const renderWidth = 2880;
const renderHeight = (renderWidth / realWidth) * realHeight;

interface IProps {
  currentActiveScene?: (sceneInstance: Phaser.Scene) => void;
  className?: string;
}

export const PhaserGame = forwardRef<IRefPhaserGame, IProps>(({ currentActiveScene, className }, ref) => {
  const game = useRef<Phaser.Game | null>(null);

  useLayoutEffect(() => {
    if (game.current) return;
    game.current = CryptoRumbleHeroesStart({
      parent: 'heroes-container',
      width: renderWidth,
      height: renderHeight,
    });
    if (typeof ref === 'function') {
      ref({ game: game.current, scene: null });
    } else if (ref) {
      ref.current = { game: game.current, scene: null };
    }
    const el = document.getElementById('heroes-container')!;
    const resize = () => {
      const width = Math.max(minWidth, window.document.body.clientWidth);
      const scale = width / renderWidth;
      el.style.transform = `scale(${scale})`;
      const left = Math.max(0, (minWidth - window.document.body.clientWidth) / 2);
      el.style.left = `-${left}px`;
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
      if (!game.current) return;
      game.current.destroy(true);
      game.current = null;
    };
  }, [ref]);

  useEffect(() => {
    EventBus.on('current-scene-ready', (sceneInstance: Phaser.Scene) => {
      currentActiveScene?.(sceneInstance);
      if (typeof ref === 'function') {
        ref({ game: game.current, scene: sceneInstance });
      } else if (ref) {
        ref.current = { game: game.current, scene: sceneInstance };
      }
    });
    return () => {
      EventBus.removeListener('current-scene-ready');
    };
  }, [currentActiveScene, ref]);

  return <CptStyle className={className} id="heroes-container"></CptStyle>;
});

const CptStyle = styled.div`
  transform: scale(0.5);
  transform-origin: 0 0;
  width: 2880px;
  height: 1600px;
  > canvas {
    display: block;
  }
`;
