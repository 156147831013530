import { defineChain } from 'viem';
import { lineaTestnet } from 'viem/chains';

export const b2Testnet = defineChain({
  id: 1123,
  name: 'B2-Habitat',
  network: 'B2-Habitat',
  nativeCurrency: { name: 'tBTC', symbol: 'tBTC', decimals: 18 },
  rpcUrls: { default: { http: ['https://b2-testnet.alt.technology'] }, public: { http: ['https://b2-testnet.alt.technology'] } },
  blockExplorers: { default: { name: 'blockscout', url: 'https://testnet-explorer.bsquared.network' } },
  contracts: { multicall3: { address: '0x91D416d939baA3Aa822DD1B776fC5e9610b952C2', blockCreated: 167020 } },
  testnet: true,
});

export const b2Mainnet = defineChain({
  id: 223,
  name: 'B² Mainnet',
  network: 'B² Mainnet',
  nativeCurrency: { name: 'BTC', symbol: 'BTC', decimals: 18 },
  rpcUrls: { default: { http: ['https://b2-mainnet.alt.technology'] }, public: { http: ['https://b2-mainnet.alt.technology'] } },
  blockExplorers: { default: { name: 'blockscout', url: 'https://explorer.bsquared.network' } },
  contracts: { multicall3: { address: '0x0ecc534b133cEb12655b1BA32904531a0A39e030', blockCreated: 384061 } },
});

export const SupportChains = [b2Testnet, lineaTestnet] as const;

SupportChains.forEach((chain) => {
  (chain as any).iconUrl = `/chain/${chain.id}.svg`;
});

export const SupportChainIds = SupportChains.map((i) => i.id);

export type ISupportChainIds = (typeof SupportChainIds)[number];

export function IsSupportChainId(chainId: number): chainId is ISupportChainIds {
  return SupportChainIds.includes(chainId as any);
}
