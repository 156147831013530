import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect } from 'react';
import styled from 'styled-components';

export const PageAirdropPcP: React.FC<{ className?: string; children?: React.ReactNode; fontSize?: number; fontWeight?: number }> = (props) => {
  return (
    <PageIndexStyle className={props.className}>
      <div className="left"></div>
      <div className="center" style={{ fontSize: props.fontSize, fontWeight: props.fontWeight }}>
        {props.children}
      </div>
      <div className="right"></div>
    </PageIndexStyle>
  );
};

const PageIndexStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  > .center {
    font-weight: 400;
  }
  > .left,
  > .right {
    width: 4px;
    height: 4px;
    background-color: #fff;
    transform: rotate(45deg);
    border-radius: 0%.5;
  }
`;
