import { darkTheme, lightTheme } from '@rainbow-me/rainbowkit';

export const RainbowKitTheme = darkTheme({
  // accentColor: '#6673FF',
  borderRadius: 'large',
  fontStack: 'system',
});
// RainbowKitTheme.colors.modalBackground = '#357eb0';
// RainbowKitTheme.colors.connectButtonBackground = '#27a69a';
// RainbowKitTheme.colors.connectButtonInnerBackground = 'linear-gradient(0deg, rgba(255, 255, 255, 0.075), rgba(255, 255, 255, 0.25))';
