import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import bg from 'assets/images/index/bg.jpg';
import bg0 from 'assets/images/index/bg-0.jpg';
import crTitle from 'assets/images/index/crTitle.png';
import bg1 from 'assets/images/index/bg-1.png';
import bg10 from 'assets/images/index/bg-10.png';
import { PhaserGame } from './CryptoRumbleHeroes/PhaserGame';
import { useRequestAnimationFrame } from 'hook/useRequestAnimationFrame';
import { useMouse } from 'hook/useMouse';

export const CryptoRumbleIndex: React.FC<{}> = (props) => {
  // const mouse = useMouse();
  // const el0 = useRef<HTMLImageElement>(null);
  // const el1 = useRef<HTMLImageElement>(null);

  // useRequestAnimationFrame((delta, fps) => {
  //   const width = window.document.body.clientWidth / 2;
  //   const height = window.document.body.clientHeight / 2;
  //   const mPos = { x: mouse.current?.clientX ?? width, y: mouse.current?.clientY ?? height };
  //   const diff = { x: (width - mPos.x) / width, y: (height - mPos.y) / height };
  //   if (el0.current) el0.current.style.transform = `translate(${-diff.x * 1.5}vw, ${diff.y * 6}px)`;
  //   if (el1.current) el1.current.style.transform = `translate(${diff.x}vw, ${diff.y * 4}px)`;
  // });
  return (
    <CryptoRumbleIndexStyle>
      <img className="bg bg-size" src={bg} />
      <img className="bg bg-0" src={bg0} />
      <img className="bg bg-1" src={bg1} />
      <img className="bg bg-10" src={bg10} />
      <PhaserGame className="heroes" />
    </CryptoRumbleIndexStyle>
  );
};

const CryptoRumbleIndexStyle = styled.div`
  width: 100vw;
  overflow: hidden;
  transform: scale(1);
  position: relative;
  > .bg {
    width: 100vw;
    object-fit: cover;
    display: block;
    user-select: none;
    pointer-events: none;
    &.bg-size {
      width: 100vw;
      z-index: 11;
      position: relative;
      opacity: 0.3;
    }
    &.bg-0 {
      filter: blur(2px);
      position: absolute;
      z-index: 1;
      width: 103vw;
      left: -1.5vw;
      top: -8px;
      /* transition: transform ease-out 2s; */
      animation: bg0Move ease-in-out 10s infinite;
      @keyframes bg0Move {
        0% {
          transform: translateX(-1.5vw) translateY(-6px);
        }
        50% {
          transform: translateX(1.5vw) translateY(6px);
        }
        100% {
          transform: translateX(-1.5vw) translateY(-6px);
        }
      }
    }
    &.bg-1 {
      position: absolute;
      top: 0;
      z-index: 2;
    }
    &.bg-10 {
      filter: blur(0.2px);
      position: absolute;
      bottom: -19%;
      z-index: 10;
      width: 101vw;
      left: -0.5vw;
      animation: bg10Move ease-in-out 10s infinite;
      @keyframes bg10Move {
        0% {
          transform: translateX(0.5vw) translateY(-4px);
        }
        50% {
          transform: translateX(-0.5vw) translateY(4px);
        }
        100% {
          transform: translateX(0.5vw) translateY(-4px);
        }
      }
    }
  }

  > .title {
    position: absolute;
    z-index: 12;
    top: calc(var(--vh, 1vh) * 28);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-direction: column;
    pointer-events: none;

    > img {
      animation: CrTitleAni ease-in-out 2s infinite;
      max-width: 90vw;
      @keyframes CrTitleAni {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1);
        }
      }
    }
    > div {
      color: #fff;
      font-size: 20px;
      font-weight: 900;
      text-align: center;
    }
  }
  > .heroes {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    /* pointer-events: none; */
  }
  @media screen and (max-width: 1000px) {
    margin-top: 6vw;
    /* margin-left: -10vw; */
    transform: scale(1.2);
    > .title {
      top: 20vw;
      > img {
        max-width: 30vw;
      }
      > div {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
