import { Scene } from 'phaser';
import { PageIndexHeroes } from '../main';

export class Boot extends Scene {
  constructor() {
    super('Boot');
  }

  preload() {
    // Preloader
    const heroPath = 'https://zypher-static.s3.us-west-2.amazonaws.com/lib/CryptoRumble/public';
    PageIndexHeroes.forEach((hero) => {
      this.load.spineJson(`${hero}-json`, `${heroPath}/hero-preview-mini/${hero}/hero.json`);
      this.load.spineAtlas(`${hero}-atlas`, `${heroPath}/hero-preview-mini/${hero}/hero.atlas`);
    });
    this.load.image('line', `${heroPath}/item-preview/line.png`);
  }

  create() {
    console.log('Boot.create');
    this.scene.start('MainMenu');
  }
}
