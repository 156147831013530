import React, { useEffect } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'assets/images/index/introduction.jpg';
import IndImg1 from 'assets/images/index/ind-1.jpg';
import IndImg2 from 'assets/images/index/ind-2.jpg';
import IndImg3 from 'assets/images/index/ind-3.jpg';
import IndImg4 from 'assets/images/index/ind-4.jpg';
import { ReactComponent as IndSvg1 } from 'assets/images/index/ind-1.svg';
import { ReactComponent as IndSvg2 } from 'assets/images/index/ind-2.svg';
import { ReactComponent as IndSvg3 } from 'assets/images/index/ind-3.svg';
import { ReactComponent as IndSvg4 } from 'assets/images/index/ind-4.svg';

const Items = [
  { text: 'Buy Mystery Boxes', bg: IndImg1, icon: <IndSvg1 width={32} /> },
  { text: 'Combination Lineup', bg: IndImg2, icon: <IndSvg2 width={32} /> },
  { text: 'PVE & PVP', bg: IndImg3, icon: <IndSvg3 width={32} /> },
  { text: 'Earn Rewards', bg: IndImg4, icon: <IndSvg4 width={32} /> },
];

export const IndexIntroduction: React.FC<{}> = (props) => {
  return (
    <IndexIntroductionStyle style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <div className="text">
        <div className="title">Introduction</div>
        <div className="des">Learn about Crypto Rumble’s unique properties</div>
      </div>
      <div className="items">
        {Items.map((item) => {
          return (
            <div className="item" key={item.text}>
              <div className="img">
                <img src={item.bg} />
              </div>
              <div className="text">
                {item.icon}
                {item.text}
              </div>
            </div>
          );
        })}
      </div>
    </IndexIntroductionStyle>
  );
};

const IndexIntroductionStyle = styled.div`
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 100px;
  gap: 60px;
  min-height: 800px;
  /* backdrop-filter: blur(2px); */
  > .items {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-width: 1024px;
    justify-content: center;
    > .item {
      width: 460px;
      /* height: 240px; */
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-direction: column;
      max-width: 90vw;
      padding: 20px 20px 0;
      border-bottom: 3px solid rgb(0 0 0 / 70%);
      cursor: pointer;
      > .img {
        border-radius: 16px;
        overflow: hidden;
        transform: scale(1);
        > img {
          transition: all ease-in-out 1s;
          width: 100%;
          /* max-width: 80vw; */
          overflow: hidden;
          display: block;
        }
      }
      > .text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        gap: 16px;
      }
      &:hover {
        animation: headShake 0.8s ease-in-out;
        > .img {
          > img {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  > .text {
    color: #fff;
    text-align: center;
    > .title {
      font-size: 32px;
      font-weight: 700;
    }
    > .des {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;
