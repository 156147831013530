import Phaser, { AUTO, Game } from 'phaser';
import { SpinePlugin } from '@esotericsoftware/spine-phaser';
import { Boot } from './scenes/Boot';
import { MainMenu } from './scenes/MainMenu';

//  Find out more information about the Game Config at:
//  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config: Phaser.Types.Core.GameConfig = {
  type: AUTO,
  backgroundColor: 'transparent',
  transparent: true,
  scene: [Boot, MainMenu],
  input: { mouse: { preventDefaultWheel: false } },
  plugins: {
    scene: [{ key: 'spine.SpinePlugin', plugin: SpinePlugin, mapping: 'spine' }],
  },
};
// const clientWidth = window.document.body.clientWidth;

const scale = 0.13;
export const PageIndexHeroes = [
  { name: 'Thief', atks: ['FallenProphet'], atkxy: { dx: 780, dy: 0 }, left: 430, top: 760, scaleX: scale, scaleY: scale },
  {
    name: 'RadiantMage',
    atkItems: { items: Array.from({ length: 10 }).map(() => 'line'), bx: 420, by: -220, tx: 0, ty: -140 },
    atks: ['FallenProphet', 'ScarletMage'],
    left: 680,
    top: 1200,
    scaleX: scale,
    scaleY: scale,
  },
  {
    name: 'Magician',
    atkItems: { items: ['cr'], bx: 260, by: -188, tx: 0, ty: -140, scale: 0.5, dr: Math.PI },
    atks: ['FallenProphet'],
    left: 920,
    top: 1380,
    scaleX: scale,
    scaleY: scale,
  },

  {
    name: 'FallenProphet',
    atkItems: { items: Array.from({ length: 5 }).map(() => 'h'), bx: 220, by: -300, tx: 0, ty: -140, scale: 0.3, dr: Math.PI },
    atks: ['Magician', 'RadiantMage'],
    left: 2320,
    top: 1250,
    scaleX: -scale,
    scaleY: scale,
  },
  { name: 'ScarletMage', atks: ['Magician'], atkxy: { dx: 500, dy: 0 }, left: 2000, top: 1390, scaleX: -scale, scaleY: scale },
];

export const CryptoRumbleHeroesStart = (option: Partial<Phaser.Types.Core.GameConfig>) => {
  console.log('CryptoRumbleHeroesStart');
  return new Game({ ...config, ...option });
};
