import { ContactShadows, Environment, Image, MeshReflectorMaterial, ScrollControls, useScroll, useTexture } from '@react-three/drei';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
// import { easing } from 'maath';
extend({ Mesh: THREE.Mesh, BoxGeometry: THREE.BoxGeometry, MeshStandardMaterial: THREE.MeshStandardMaterial });

const heroList = new Array(3 * 9).fill(0).map((_, i) => {
  const q = Math.floor(i / 9) + 1;
  return `https://zypher-static.s3.amazonaws.com/lib/CryptoRumble/public/hero-card/dist/${q}-${(i % 9) + 1}-2.png`;
});

// https://codesandbox.io/p/sandbox/image-gallery-lx2h8?file=%2Fsrc%2Findex.js%3A21%2C1
export const PageAirdropHeroes: React.FC<{ className?: string }> = (props) => {
  return (
    <PageIndexStyle>
      <Canvas camera={{ position: [0, 0, 10], fov: 15, near: 9 }}>
        <Cards>
          <Carousel />
        </Cards>
      </Canvas>
    </PageIndexStyle>
  );
};
const PageIndexStyle = styled.div`
  width: 100vw;
  height: 500px;
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  z-index: 2;
`;

function Carousel({ radius = 6, count = 36 }) {
  return Array.from({ length: count }, (_, i) => (
    <Card
      key={i}
      url={heroList[Math.floor(Math.random() * heroList.length)]}
      position={[Math.sin((i / count) * Math.PI * 2) * radius, 0, Math.cos((i / count) * Math.PI * 2) * radius]}
      rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
    />
  ));
}

function Card({ url, ...props }: any) {
  // const ref = useRef<any>(null);
  // useFrame((state, delta) => {
  //   // easing.damp3(ref.current!.scale, hovered ? 1.15 : 1, 0.1, delta);
  //   // easing.damp(ref.current!.material, 'zoom', hovered ? 1 : 1.5, 0.2, delta);
  // });
  // ref={ref} zoom={0.75}
  return <Image url={url} transparent side={THREE.DoubleSide} {...props}></Image>;
}

export const Cards: React.FC<{ children?: React.ReactNode }> = (props) => {
  const ref = useRef<THREE.Group>(null);
  const speedUp = useRef(0);
  useFrame((state, delta) => {
    if (ref.current) {
      if (speedUp.current) {
        // speedUp.current += 0.0001;
        ref.current.rotation.y += speedUp.current;
      } else {
        ref.current.rotation.y += 0.001;
      }
    }
    state.events.update?.(); // Raycasts every frame rather than on pointer-move
    // easing.damp3(state.camera.position, [0, 0, 10], 0.3, delta); // Move camera
    state.camera.lookAt(0, 0, 0); // Look at center
  });
  return (
    <group
      onPointerOver={() => {
        speedUp.current = 0.002;
      }}
      onPointerLeave={() => {
        speedUp.current = 0;
      }}
      rotation={[0, 0, 0]}
      ref={ref}
    >
      {props.children}
    </group>
  );
};
