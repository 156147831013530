import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Modal, message, notification } from 'antd';
import { IsSupportChainId, SupportChains } from 'constants/chains';
import { GlobalVar } from 'constants/constants';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { stateAppChain } from 'states/wallet';
import styled from 'styled-components';
import { useAccount, useChainId } from 'wagmi';

export const AppHeader: React.FC<{}> = (props) => {
  const _appChain = useSetRecoilState(stateAppChain);
  const acc = useAccount();
  const [notificationApi, notificationCtx] = notification.useNotification();
  const [messageApi, messageCtx] = message.useMessage();
  const [modalApi, modalCtx] = Modal.useModal();
  GlobalVar.notification = notificationApi;
  GlobalVar.message = messageApi;
  GlobalVar.modal = modalApi;

  useEffect(() => {
    const net = acc.chain;
    if (!net) return;
    if (!IsSupportChainId(net.id)) return;
    const target = SupportChains.find((c) => c.id === net.id);
    if (!target) return;
    _appChain(target);
  }, [acc.chain?.id]);

  return (
    <CptStyles>
      <div className="left">
        <NavLink className="logo" to="/">
          <img src="/logo.png" />
        </NavLink>
        <NavLink to="/airdrop">Airdrop</NavLink>
        {/* <NavLink target="_blank" to="https://docs.cryptorumble.io/">
          Docs
        </NavLink> */}
        {/* <NavLink to="/game">Game</NavLink> */}
      </div>
      <div className="right">
        <ConnectButton />
      </div>
      {notificationCtx}
      {messageCtx}
      {modalCtx}
    </CptStyles>
  );
};

const CptStyles = styled.div`
  box-sizing: border-box;
  width: 100vw;
  padding: 0 12px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 20;
  backdrop-filter: blur(5px);
  @media screen and (max-width: 1000px) {
    height: 60px;
  }
  > .left {
    display: flex;
    align-items: center;
    gap: 12px;
    > .logo {
      > img {
        height: 60px;
      }
    }
    @media screen and (max-width: 460px) {
      > a {
        /* display: none; */
        &.logo {
          display: none;
        }
      }
    }
    > a {
      transition: all ease 0.2s;
      color: #fff;
      &:hover {
        text-decoration: underline;
        transform: scale(1.1);
        color: #eee;
      }
    }
  }
`;
