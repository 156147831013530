export default {
  "1123": {
    "Hero": {
      "address": "0xe010DF8f3AE9c83Da0c4411d28C252Ef59366EeD"
    },
    "HeroEdition2": {
      "address": "0x5f441d16bA9A5e3a824f4c287eDA8019F97418f6"
    },
    "Restaking": "0x159879B72B1bE7007aC56c4DcbbC31545F8D57bb",
    "WETH": "0x4AC1Ba5885929aFDdbf035bA03013836db27012C"
  },
  "59140": {
    "Hero": {
      "address": "0x26368C78A33F8F9E122c07E883D46eF3d7b944b1"
    },
    "HeroEdition2": {
      "address": "0x76E08f9D5f76590E12427F003325768290602De1"
    },
    "Restaking": "0x3BbBe5929db5EAdF580537874bBA0a961F505E40",
    "WETH": "0x5131bc5Ed480a524932D2638922616fE968374FE"
  }
} as const;